body {
  margin: 0;
  border: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3070a0;
  color: white;
  font-size:18px;
}

a 
{
  color: white;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div
{
  margin: 0px;
  padding: 0px;
  border: 0px;
}

nav
{
  margin: 0;
  padding: 0;
  border:0;
}

nav ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
  border:0;
  overflow: hidden;
  background-color: #004070;
  font-size:20px;
}

nav li
{
  float: left;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
    /*
  padding: 8px 16px;
  float: left;
  width: auto;
  display: block;
  border:none;
  outline:0;
  white-space:normal;
  min-height:100%;
  */
}

nav li a
{
  display: inline;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav li a:hover {
  background-color: #111;
}

nav .active {
  background-color: #002050;
}

section
{
  background-color: #5090c0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
}

.ro-start {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #5090c0;
}

.ro-start h1 
{
  font-family: 'Raleway',sans-serif;
  font-size:48px;
  padding:12px 24px
}


.ro-searchpage h1
{
  font-size:48px;
}


.ro-search
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
}

.ro-songthumb
{
  width: 256px;
  height: 256px;
  background-color: #37a;
  margin: 10px;
  padding:10px;
}

.ro-songthumb .ro-name
{
  overflow: hidden;
  text-overflow: ellipsis;
}

.ro-songthumb img
{
  height: 164px;
  align-self: center; 
}

.ro-songthumb .ro-name
{
  font-size:24px;
  font-weight: bold;
  width: 256px;
  white-space: nowrap;
}

.ro-songthumb .ro-audio
{
  margin-left: 0;
  margin-right: 0;
}

.ro-songpage .ro-name
{
  font-size:48px;
  font-weight: bold;
}

.ro-songpage .ro-artist
{
  font-size:24px;
  font-style: italic;
}

.ro-songpage .ro-linerNotes
{
}
.ro-songpage .ro-lyrics
{
}

.ro-audio
{
  margin-left: 32px;
  margin-right: 32px;
}

.ro-login
{
  min-width: 100px;
  width: 100px;
}

.ro-right
{
  float:right;
}
